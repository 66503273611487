import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

import { AddonInputType, Media, UserPlan } from "@/types";
import { AffiliateStatus, UserAffiliateInfo } from "@/types/affiliate";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function toStupidFixed(source: number) {
	// const isDecimal = source % 1 !== 0;
	// return isDecimal ? source.toFixed(2) : source;
	return Math.ceil(source);
}

export function toHeaderCase(str: string) {
	return str
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
		.join(" ");
}

export function toCompoundDate(source: string | Date) {
	const date = new Date(source);
	const currentDate = new Date();

	const options: Intl.DateTimeFormatOptions = {
		day: "2-digit",
		month: "short",
	};

	if (date.getFullYear() !== currentDate.getFullYear()) {
		options.year = "numeric";
	}

	return date.toLocaleDateString(undefined, options);
}

export function toMessageDate(source: string | Date) {
	const date = new Date(source);
	const currentDate = new Date();

	const options: Intl.DateTimeFormatOptions = {
		month: "short",
		day: "numeric",
		hour: "numeric",
		minute: "2-digit",
	};

	if (date.getFullYear() !== currentDate.getFullYear()) {
		options.year = "numeric";
	}

	return date.toLocaleDateString(undefined, options);
}

export function getInitials(firstName?: string, lastName?: string) {
	if (!firstName && !lastName) return "N/A";

	const first = firstName?.charAt(0).toUpperCase() ?? "";
	const second = lastName?.charAt(0).toUpperCase() ?? "";

	return `${first}${second}`;
}

export function errorIntoString(error: unknown): string {
	if (error === undefined || error === null) return "Unknown error";
	if (typeof error === "string") return error;

	const ex = error as object;

	if ("data" in ex) {
		const y = ex.data as { message: string };
		return y.message;
	}

	return "message" in ex ? (ex.message as string) : ex.toString();
}

export function toLocaleDate(newDate: string) {
	const date = new Date(newDate);
	const formattedDate = date.toLocaleDateString("en-US", {
		month: "long",
		day: "numeric",
		year: "numeric",
	});

	return formattedDate;
}

export function deserializeAddonValue(
	addonType: AddonInputType | null,
	value: string | number | boolean,
) {
	let type_ = addonType;

	if (type_ === null) {
		if (typeof value === "number") type_ = AddonInputType.number;
		if (typeof value === "boolean") type_ = AddonInputType.toggle;

		if (typeof value === "string") {
			if (value === "true" || value === "false") type_ = AddonInputType.toggle;
			if (value.match(/^\d*$/) !== null) type_ = AddonInputType.number;
		}

		if (type_ === null) throw new Error("un-deserializable input value");
	}

	switch (type_) {
		case AddonInputType.number:
			return typeof value === "string" ? Number.parseFloat(value) : (value as number);
		case AddonInputType.toggle:
			return typeof value === "string" ? value === "true" : (value as boolean);
	}
}

export function removeUUIDv4Prefix(filename: string) {
	if (typeof filename !== "string") {
		return filename;
	}

	const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}-/i;
	const endSplit = filename.split("/");

	return endSplit[endSplit.length - 1].replace(uuidRegex, "");
}

export function extractFromS3Url(url: string) {
	const s3Prefix = `https://${process.env.NEXT_PUBLIC_AWS_BUCKET_NAME}.s3.${process.env.NEXT_PUBLIC_AWS_REGION}.amazonaws.com/`;
	if (url.startsWith(s3Prefix)) return url.replace(s3Prefix, "");

	return url;
}

export function isUserPremium(plan?: UserPlan) {
	return plan === UserPlan.yearly || plan === UserPlan.monthly;
}

export function isUserAdmin(role: string | undefined) {
	return role == "ADMIN" || role == "DIRECTOR";
}

/**
 * Checks if user is allow to redeem an affiliate payout.
 *
 * For the payout to be eligible, the current date should be one month from the {@link createdAt} and with {@link status} `ELIGIBLE`.
 */
export function isAffiliateEligible(status: AffiliateStatus, createdAt: Date) {
	if (status !== AffiliateStatus.ELIGIBLE) return false;

	const now = new Date();
	return now.getFullYear() > createdAt.getFullYear() || now.getMonth() > createdAt.getMonth();
}

/**
 * Checks if the passed user is allowed to create new affiliates.
 *
 * Currently, false is only returned the user has a {@link UserPlan.free}, and >= 3 affiliates.
 */
export function isAffiliateShareDisabled(plan: UserPlan, affiliateCount: number) {
	// return plan === UserPlan.free && affiliateCount >= 6;
	return false;
}

export function shuffleArray<T>(array: T[]) {
	const shuffled = array.slice();

	for (let i = shuffled.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
	}

	return shuffled;
}

export function isFileVideo(file: File) {
	return file.type.startsWith("video/");
}

export function getPreferredMedia(media: Media[]): Media | null {
	const spotlight = media.find((x) => x.isSpotlight);
	if (spotlight !== undefined) return spotlight;
	return media.at(0) ?? null;
}

export function getThumbnail(thumbnailImage?: string): string | undefined {
	return thumbnailImage !== undefined && thumbnailImage.length > 0 ? thumbnailImage : undefined;
}

export function getPreviewMedia(media: Media[], thumbnailImage?: string): Media | null {
	if (thumbnailImage !== undefined && thumbnailImage.length > 0) {
		return {
			_id: "",
			createdAt: "",
			updatedAt: "",
			type: "IMAGE",
			isSpotlight: false,
			url: thumbnailImage,
		};
	}

	const spotlight = media.find((x) => x.isSpotlight);
	if (spotlight !== undefined) return spotlight;

	return media.find((x) => x.type === "IMAGE") ?? null;
}

export function clamp(min: number, max: number, source: number) {
	return Math.min(Math.max(source, min), max);
}

export function getSSOClientId(type: "apple" | "google") {
	switch (type) {
		case "apple":
			return process.env.NEXT_PUBLIC_APPLE_CLIENT_ID!;
		case "google":
			return process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID!;
		default:
			throw new Error(`Unsupported SSO type: ${type}`);
	}
}
