import { OrderStatus } from "@/types/orders";
import { DataStatus, FAQ, Notification, OrderNote, Review, ServiceStatus, User, UserNote } from ".";

export interface ServerCollectionResponse<T> {
	docs: T[];
	totalDocs: number;
	limit: number;
	page: number;
	totalPages: number;
	pagingCounter: number;
	prevPage: number | null;
	nextPage: number | null;
	hasPrevPage: boolean;
	hasNextPage: boolean;
}

export type BlogCategory = {
	_id: string;
	name: string;
	icon: string;
	dataStatus: string;
	createdAt: string;
	updatedAt: string;
	__v: number;
	id: string;
};

export type BlogCategoriesResponse = {
	docs: BlogCategory[];
	totalDocs: number;
	limit: number;
	totalPages: number;
	page: number;
	pagingCounter: number;
};

export type Policies = {
	_id: string;
	title: string;
	description: string;
	date: string;
	dataStatus: string;
	createdAt: string;
	updatedAt: string;
	__v: number;
};

export type Keyword = {
	_id: string;
	name: string;
	icon: string;
	dataStatus: string;
	createdAt: string;
	updatedAt: string;
	__v: number;
	id: string;
};

export type KeywordResponse = {
	docs: Keyword[];
	totalDocs: number;
	limit: number;
	totalPages: number;
	page: number;
	pagingCounter: number;
};

export type FAQResponse = {
	docs: FAQ[];
	totalDocs: number;
	limit: number;
	totalPages: number;
	page: number;
	pagingCounter: number;
};

export type ReviewResponse = {
	docs: Review[];
	totalDocs: number;
	limit: number;
	totalPages: number;
	page: number;
	pagingCounter: number;
	hasPrevPage: boolean;
	hasNextPage: boolean;
	prevPage: null | number;
	nextPage: null | number;
};

export type UpdateBlogCategoryDto = {
	name?: string;
	icon?: string;
	dataStatus?: string;
};

export type UpdateKeywordDto = {
	name?: string;
	icon?: string;
	dataStatus?: string;
};

export type UpdateReviewDto = {
	review?: string;
	rating?: number;
};

export type UpdateNotificationDto = {
	_id?: string;
	isRead: boolean;
};

export type NotificationResponse = {
	docs: Notification[];
	totalDocs: number;
	limit: number;
	totalPages: number;
	page: number;
	pagingCounter: number;
};

export type Category = {
	_id: string;
	title: string;
	logo: string;
	description: string;
	subHeading: string;
	createdAt: string;
	updatedAt: string;
	__v: number;
	serviceTitles: string;
	servicesCount: number;
	id: string;
};

export type CategoryCollection = ServerCollectionResponse<Category>;

export type CustomOffer = {
	_id: string;
	title: string;
	logo: string;
	description: string;
	createdAt: string;
	updatedAt: string;
	__v: number;
	serviceTitles: string;
	servicesCount: number;
	id: string;
};

export type Package = {
	price: number;
	currency: string;
	title: string;
	description: string;
	attributes?: Record<string, string>;
	_id: string;
	id: string;
	createdAt: string;
	updatedAt: string;
	dueDays: number;
};

export type Media = {
	url: string;
	type: string;
	isSpotlight: boolean;
	_id: string;
	createdAt: string;
	updatedAt: string;
};

export type Addon = {
	description: string;
	amount: number;
	limit: number;
	inputType: AddonInputType;
	_id: string;
	unit: string;
	createdAt: string;
	updatedAt: string;
};

export enum AddonInputType {
	number = "NUMBER",
	toggle = "TOGGLE",
}

export type Service = {
	_id: string;
	title: string;
	description: string;
	categoryId: Category;
	packages: Package[];
	media: Media[];
	thumbnailImage: string;
	addons: Addon[];
	isPopular?: boolean;
	createdAt: string;
	updatedAt: string;
	slug?: string;
	status: ServiceStatus;
	dataStatus: DataStatus;
	parentServiceId?: Omit<Service, "parentServiceId" | "categoryId"> & {
		parentServiceId: null;
		categoryId: string;
	};
	demoUrl: string | null;
	__v: number;
	id: string;
};

export type ServicesApiResponse = {
	docs: Service[];
	totalDocs: number;
	limit: number;
	totalPages: number;
	page: number;
	pagingCounter: number;
	hasPrevPage: boolean;
	hasNextPage: boolean;
	prevPage: null | number;
	nextPage: null | number;
};

export type ServiceFrequentlyBought = {
	_id: string;
	serviceId: Service;
	serviceIds: Service[];
};

export type BasketAddonItem = {
	type: AddonInputType;
	value: number | boolean;
};

export type BasketAddonItemWithoutType = Omit<BasketAddonItem, "type"> & {
	type?: BasketAddonItem["type"];
};

export type BasketItem = {
	serviceId: string;
	packageId: string;
	quantity: number;

	/**
	 * Addon added to this item adjourned by their addon ids.
	 */
	addons: Record<string, BasketAddonItem>;
};

export type Basket = {
	/**
	 * Record of basket items keyed by unique basket item identifiers.
	 */
	items: Record<string, BasketItem>;
};

export type UserResponseDto = {
	docs: User[];
	hasNextPage: boolean;
	hasPrevPage: boolean;
	limit: number;
	nextPage: number | null;
	page: number;
	pagingCounter: number;
	prevPage: number | null;
	totalDocs: number;
	totalPages: number;
};

export type UserNoteResponseDto = {
	docs: UserNote[];
	limit: number;
	nextPage: number | null;
	page: number;
	pagingCounter: number;
	prevPage: number | null;
	totalDocs: number;
	totalPages: number;
};

export type OrderNoteCollectionResponse = ServerCollectionResponse<OrderNote>;

export interface ServiceReview {
	_id: string;
	serviceId: string;
	userId: Pick<User, "firstName" | "lastName" | "email" | "avatar"> | null;
	rating: number;
	comment: string;
	createdAt: string;
	updatedAt: string;
	__v: number;
}

export interface SavedServices {
	_id: string;
	userId: User;
	serviceIds: Service[];
	__v: number;
}

export interface SavedBlogs {
	_id: string;
	userId: User;
	blogIds: BlogArticle[];
	__v: number;
}

export enum BlogArticleStatus {
	DRAFT = "DRAFT",
	PUBLISHED = "PUBLISHED",
	ARCHIVED = "ARCHIVED",
}

export interface BlogArticle {
	_id: string;
	title: string;
	thumbnail: string;
	media: Media[];
	blogCategories: BlogCategory[];
	keywords: Keyword[];
	postedAt: string;
	readingTime: string;
	jsonExport: string;
	htmlExport: string;
	status: BlogArticleStatus;
	authorId: User | null;
	isPopular: boolean;
	createdAt: string;
	updatedAt: string;
	isPremium: boolean;
	__v: number;
	id: string;
}

export interface BlogComment {
	_id: string;
	userId: User | null;
	blogId: BlogArticle;
	text: string;
	createdAt: string;
	updatedAt: string;
}

export interface BlogLike {
	_id: string;
	userId: User;
	blogId: Pick<BlogArticle, "_id" | "title" | "id">;
	createdAt: string;
	updatedAt: string;
}

export type BlogLikesCollectionResponse = ServerCollectionResponse<BlogLike>;
export type BlogCommentCollectionResponse = ServerCollectionResponse<BlogComment>;
export type BlogsCollectionResponse = ServerCollectionResponse<BlogArticle>;

export type AffiliateUserOrder = {
	_id: string;
	userId: string;
	orderStatus: OrderStatus;
	amount: number;
	createdAt: string;
};

export type AffiliateUser = Pick<User, "firstName" | "lastName" | "avatar"> & {
	_id: string;
	orders: AffiliateUserOrder[];
	createdAt: string;
};
