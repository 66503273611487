export interface Price {
	duration: UserPlan;
	amount: number;
	id?: string;
}

export enum FeatureStatus {
	RIGHT = "RIGHT",
	WRONG = "WRONG",
	NONE = "NONE",
}
export interface Feature {
	name: string;
	description: string;
	attributes: string[];
	status: FeatureStatus;
	_id?: string;
}

export interface FeatureGroup {
	title: string;
	features: Feature[];
}

export interface Colors {
	bgColor: string;
	fontColor: string;
	buttonColor: string;
	priceColor: string;
}

export enum PlanStatus {
	PUBLISHED = "PUBLISHED",
	DRAFT = "DRAFT",
	ARCHIVED = "ARCHIVED",
}

export interface Plan {
	name: string;
	prices: Price[];
	featureGroups: FeatureGroup[];
	colors: Colors;
	status: PlanStatus;
	isCustomPlan?: boolean;
	slug?: string;
	_id: string;
	__v?: number;
}

export interface BenefitCategory {
	[x: string]: any;
	title: string;
	attributes: string[];
	features: FeatureGroup[];
}

export enum UserPlan {
	notSelected = "NOT_SELECTED",
	free = "FREE",
	monthly = "MONTHLY",
	yearly = "YEARLY",
}
