import Link from "next/link";
import Image from "next/image";
import { PauseIcon } from "lucide-react";
import { type MouseEventHandler, useRef, useState } from "react";

import { cn } from "@/lib/utils";
import type { Media } from "@/types";
import { usePlayerControls } from "@/lib/hooks";

import PlayIcon from "@/components/icons/Play";

type Props = {
	data: Media | null;
	altHref?: string;
	className?: string;
	childClassName?: string;
	thumbnail?: string;
	disablePlayback?: boolean;
};

export default function MediaCard({
	data,
	altHref,
	className,
	childClassName,
	thumbnail,
	disablePlayback,
}: Props) {
	if (data === null || !data.url) {
		return <div>No media available</div>;
	}

	if (data.type === "VIDEO") {
		return (
			<VideoPlayer
				url={data.url}
				className={className}
				childClassName={childClassName}
				thumbnail={thumbnail}
				disablePlayback={disablePlayback}
			/>
		);
	}

	const wrapperClass = cn(
		"flex aspect-video relative w-full items-center overflow-hidden",
		className,
	);

	const wrapperChild = (
		<Image
			src={data.url}
			alt=""
			draggable={false}
			width={2048}
			height={1152}
			unoptimized
			className={cn("size-full object-contain", childClassName)}
		/>
	);

	if (altHref !== undefined) {
		return (
			<Link href={altHref} className={wrapperClass}>
				{wrapperChild}
			</Link>
		);
	}

	return <div className={wrapperClass}>{wrapperChild}</div>;
}

function VideoPlayer({
	url,
	className,
	childClassName,
	thumbnail,
	disablePlayback,
}: Pick<Media, "url"> &
	Pick<Props, "className" | "childClassName" | "thumbnail" | "disablePlayback">) {
	const videoRef = useRef<HTMLVideoElement>(null);
	const { paused, toggle } = usePlayerControls(videoRef);
	const [showThumbnail, setThumbnailVisibility] = useState(thumbnail !== undefined);

	const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
		e.stopPropagation();
		e.preventDefault();

		if (showThumbnail) setThumbnailVisibility(false);

		toggle();
	};

	return (
		<div
			className={cn(
				"relative flex aspect-video w-full items-center overflow-hidden",
				disablePlayback && "pointer-events-none",
				className,
			)}
		>
			{showThumbnail && thumbnail && (
				<Image
					src={thumbnail}
					alt=""
					draggable={false}
					width={2048}
					height={1152}
					quality={100}
					className={cn("size-full absolute inset-0 object-contain", childClassName)}
				/>
			)}

			<video
				ref={videoRef}
				playsInline
				preload="metadata"
				controls={!paused}
				poster={thumbnail}
				className={cn("size-full", disablePlayback && "hidden", childClassName)}
			>
				{!disablePlayback && <source src={`${url}#t=0.001`} />}
			</video>

			<div
				className={cn(
					"absolute inset-0 flex flex-col justify-center transition-opacity z-10",
					!paused && "opacity-0 pointer-events-none",
				)}
			>
				<button
					className="inline-flex size-16 items-center justify-center self-center rounded-full align-middle text-background"
					onClick={onClick}
				>
					<PlayIcon className="size-16" />
				</button>
			</div>
		</div>
	);
}
