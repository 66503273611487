import type { PropsWithoutRef, SVGProps } from "react";

export default function PlayIcon({ ...props }: PropsWithoutRef<SVGProps<SVGElement>>) {
	return (
		<svg
			width="60"
			height="60"
			viewBox="0 0 60 60"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path d="M45 30L22.5 42.9904L22.5 17.0096L45 30Z" fill="white" />
			<circle cx="30" cy="30" r="28.5" stroke="white" stroke-width="3" />
		</svg>
	);
}
